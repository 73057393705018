import { render, staticRenderFns } from "./GskSelect.vue?vue&type=template&id=2acc7cea&scoped=true&"
import script from "./GskSelect.vue?vue&type=script&lang=ts&"
export * from "./GskSelect.vue?vue&type=script&lang=ts&"
import style0 from "./GskSelect.vue?vue&type=style&index=0&id=2acc7cea&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2acc7cea",
  null
  
)

export default component.exports